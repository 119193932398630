import { TextField, Select2V2 } from "@components/FormsV2";
import RequiredReactSelect from "@components/FormsV2/RequiredReactSelect/RequiredReactSelect";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  useListPhasesForAccount,
  useListServiceCategoriesForAccount,
} from "../../Show/api";
import { useEffect, useState, useRef } from "react";
import { Option } from "../../Show/types";
import { useListTeamsForAccount, useListTagsForAccount } from "../api";
import { FormNumberFieldV2 } from "@components/FormsV2/FormNumberFieldV2";
import { ServicesTable } from "../../../../Services/ServicesTable/ServicesTable";
import { useDeleteSubserviceMutation } from "@generated";
import { Subservice } from "../types";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";

const General = ({
  service,
  setService,
  isManagedService,
  permission,
  editing,
  resourceOptions,
  refetch,
}) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const { phases } = useListPhasesForAccount();
  const [phaseOptions, setPhaseOptions] = useState<Option[]>([]);

  const { categories } = useListServiceCategoriesForAccount([]);
  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);

  const frequencyOptions: Option[] = [
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
    { label: "Yearly", value: "yearly" },
  ];
  const [deleteSubserviceMutation] = useDeleteSubserviceMutation();

  const [subserviceToDelete, setSubserviceToDelete] =
    useState<Subservice | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "Something went wrong. Your changes could not be saved at this time."
  );

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focusedId, setFocusedId] = useState<string | null>(null);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [service.subservices]);

  useEffect(() => {
    if (phases.length && !phaseOptions.length) {
      setPhaseOptions(phases);
    }
  }, [phases]);

  useEffect(() => {
    if (JSON.stringify(categories) !== JSON.stringify(categoryOptions)) {
      setCategoryOptions(categories);
    }
  }, [categories]);

  const addSubservice = () => {
    setService({
      ...service,
      subservices: [
        ...service.subservices,
        {
          id: `new-${Math.floor(Math.random() * 100)}`,
          name: "",
          quantity: 1,
          hours: "",
          resource: "",
          position: Number(service.subservices?.length || 0) + 1,
        },
      ],
    });
  };

  const deleteSubservice = (selectedService) => {
    if (selectedService.id.includes("new")) {
      setService({
        ...service,
        subservices: service.subservices.filter(
          (s) => s.id !== selectedService.id
        ),
      });
    } else {
      setSubserviceToDelete(selectedService);
      setShowConfirmationModal(true);
    }
  };

  const handleFailResponse = (msg) => {
    setErrorMessage(msg || "Something went wrong.");
    setShowFailAlert(true);
  };

  const confirmDelete = () => {
    setShowConfirmationModal(false);
    if (subserviceToDelete) {
      deleteSubserviceMutation({
        slug: accountSlug,
        id: Number(subserviceToDelete?.id),
      })
        .then((res) => {
          setSuccessMessage("Subservice deleted");
          setShowSuccessAlert(true);
          setLoading(false);
          refetch();
        })
        .catch((err) => {
          handleFailResponse(err?.data?.errors?.[0]?.detail);
          setShowFailAlert(true);
          setLoading(false);
          refetch();
        });
    }
  };

  const updateSubserviceData = (item) => {
    let updated = service.subservices.map((subservice) => {
      if (subservice.id === item.id) {
        return item;
      } else {
        return subservice;
      }
    });
    setService({ ...service, subservices: updated });
  };

  const resourceList = [
    { value: -1, label: "Select a Resource" },
    ...resourceOptions,
  ].map((o) => (
    <option key={o.value} value={o.value}>
      {o.label}
    </option>
  ));

  const subserviceColumns = [
    {
      header: "Subservice Name",
      cell(props) {
        return props.row.original.id === "noRow" ? (
          <div>{props.row.original.phase?.label}</div>
        ) : (
          <Form.Control
            type="text"
            value={props.row.original.name}
            onFocus={() => setFocusedId(props.row.original.id + "-name")}
            onBlur={() => setFocusedId(null)}
            autoFocus={focusedId === props.row.original.id + "-name"}
            onChange={(e) => {
              e.stopPropagation();
              updateSubserviceData({
                ...props.row.original,
                name: e.target.value,
                isChanged: true,
              });
            }}
            className="subserviceName"
          />
        );
      },
    },
    {
      header: "QTY",
      cell(props) {
        return props.row.original.id === "noRow" ? (
          ""
        ) : (
          <Form.Control
            type="number"
            min="1"
            step="1"
            value={props.row.original.quantity}
            onChange={(e) => {
              e.stopPropagation();

              updateSubserviceData({
                ...props.row.original,
                quantity: Number(e.target.value),
                isChanged: true,
              });
            }}
            ref={props.row.original.id + "-qty" === focusedId ? inputRef : null}
            onFocus={() => setFocusedId(props.row.original.id + "-qty")}
          />
        );
      },
    },
    {
      header: "Unit Hrs",
      cell(props) {
        return props.row.original.id === "noRow" ? (
          ""
        ) : (
          <Form.Control
            type="number"
            min="0"
            step="0.01"
            value={props.row.original.hours}
            onChange={(e) => {
              e.stopPropagation();
              updateSubserviceData({
                ...props.row.original,
                hours: Number(e.target.value),
                isChanged: true,
              });
            }}
            ref={props.row.original.id + "hrs" === focusedId ? inputRef : null}
            onFocus={() => setFocusedId(props.row.original.id + "hrs")}
          />
        );
      },
    },
    {
      header: "Resource",
      cell(props) {
        return props.row.original.id === "noRow" ? (
          ""
        ) : (
          <Form.Control
            as="select"
            value={props.row.original?.resource || service?.resource}
            onChange={(e) => {
              e.stopPropagation();
              updateSubserviceData({
                ...props.row.original,
                resource: e.target.value,
                isChanged: true,
              });
            }}
            onFocus={() => setFocusedId(null)}
          >
            {resourceList}
          </Form.Control>
        );
      },
    },
  ];

  const onUpdateServices = (subservices) => {
    setFocusedId(null);
    setService({ ...service, subservices });
  };

  return (
    <div className="generalTab">
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <div className="formSection">
        <Row className="firstRow">
          <Col sm={4} className="nameField">
            <TextField
              label="*Service Name"
              id="name"
              placeholder="Enter service name"
              value={service.name}
              onChange={(e) => setService({ ...service, name: e.target.value })}
              required
              readOnly={editing && permission !== "manage"}
            />
          </Col>
          <Col sm={4}>
            <RequiredReactSelect
              label="*Service Category"
              options={categoryOptions}
              placeholder="Select service category"
              value={
                categories.find((c) => c.value === service.category) ||
                undefined
              }
              onChange={(e) =>
                //@ts-ignore
                setService({ ...service, category: e.value })
              }
              isDisabled={editing && permission !== "manage"}
            />
          </Col>
          <Col sm={4}>
            <TextField
              label="SKU"
              id="sku"
              placeholder="Enter SKU"
              value={service?.sku || ""}
              onChange={(e) => setService({ ...service, sku: e.target.value })}
              required={false}
              readOnly={editing && permission !== "manage"}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={4}>
            {isManagedService ? (
              <RequiredReactSelect
                label="*Billing Frequency"
                options={frequencyOptions}
                placeholder="Select billing frequency"
                value={
                  frequencyOptions.find((o) => o.value === service.frequency) ||
                  undefined
                }
                onChange={(e) =>
                  //@ts-ignore
                  setService({ ...service, frequency: e.value })
                }
                isDisabled={editing && permission !== "manage"}
              />
            ) : (
              <RequiredReactSelect
                label="*Phase"
                options={phaseOptions}
                placeholder="Select phase"
                value={
                  phases.find((p) => p.value === service.phase) || undefined
                }
                onChange={(e) =>
                  //@ts-ignore
                  setService({ ...service, phase: e.value })
                }
                isDisabled={editing && permission !== "manage"}
              />
            )}
          </Col>
          <Col sm={4}>
            <FormNumberFieldV2
              label="Hours"
              required={false}
              min={0}
              step={0.01}
              value={service?.hours || ""}
              onChange={(e) =>
                setService({ ...service, hours: e.target.value })
              }
              placeholder="Enter hours"
              readOnly={editing && permission !== "manage"}
            />
          </Col>
          <Col sm={4}>
            <Select2V2
              label={`${!isManagedService ? "*" : ""}Resource`}
              options={resourceOptions}
              isDisabled={editing && permission !== "manage"}
              placeholder="Select resource"
              value={
                resourceOptions?.find((r) => r.value === service.resource) || {
                  value: -1,
                  label: "Select a Resource",
                }
              }
              onChange={(e) => setService({ ...service, resource: e.value })}
            />
          </Col>
        </Row>
      </div>
      <div className="subservicesContainer">
        <div className="headerContainer subservices">
          <div className="subserviceHeader">Subservices</div>
          <div className="actionBtns">
            <Button
              className="button"
              onClick={() => addSubservice()}
              disabled={loading}
            >
              Add Subservice
            </Button>
          </div>
        </div>
        {loading ? (
          <SmallSpinner />
        ) : (
          <div className="subserviceTable">
            <ServicesTable
              servicesPassed={service.subservices}
              onUpdateServices={onUpdateServices}
              serviceColumns={subserviceColumns}
              subserviceColumns={[]}
              addSubservice={() => null}
              deleteService={deleteSubservice}
              hasSettings={true}
              hasDragAndDrop={true}
              isCollapsible={false}
              placeholderText={
                editing
                  ? "No subservices found"
                  : "Add subservices to get started"
              }
            />
          </div>
        )}
        <ConfirmationModal
          show={showConfirmationModal}
          title="Delete Subservice"
          message={`Are you sure you want to delete${
            " " + subserviceToDelete?.name
          }?`}
          onConfirm={() => {
            setLoading(true);
            confirmDelete();
          }}
          onCancel={() => setShowConfirmationModal(false)}
        />
      </div>
    </div>
  );
};

export default General;
