import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import {
  useGetServiceByIdQuery,
  V1ProductResource,
  V1SubserviceResource,
} from "@generated";
import { Service, Subservice, Product } from "../types";

const useGetServiceById = (id) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError

  const { data, error, isLoading, refetch, isFetching } =
    useGetServiceByIdQuery({
      slug: accountSlug,
      id,
      include: [
        "resource",
        "phase",
        "service-category",
        "subservices",
        //@ts-ignore
        "subservices.resource",
        "products",
      ],
    });

  let serviceData: Service | undefined = undefined;

  if (data?.data) {
    try {
      const phaseId = data.data?.relationships?.phase?.data?.id;
      const categoryId =
        data.data?.relationships?.["service-category"]?.data?.id;
      const sku = data.data?.attributes?.sku;
      const resource = data.data?.relationships?.resource?.data?.id;
      const hours = data.data?.attributes?.["suggested-hours"];
      const tags = data.data?.attributes?.["tag-list"]?.map((tag) => {
        return { value: tag, label: tag };
      });

      let subservices: Subservice[] = [];

      data.data.relationships?.subservices?.data?.forEach((subservice) => {
        const sub = data?.included?.find(
          (item) => item.id == subservice.id && item.type === "subservices"
        ) as V1SubserviceResource | undefined;
        if (sub?.id && sub?.attributes?.state !== "deleted") {
          subservices.push({
            id: String(sub.id),
            name: sub.attributes.name,
            quantity: Number(sub.attributes?.quantity || 1),
            resource: String(sub?.relationships?.resource?.data?.id || ""),
            hours: sub.attributes?.["suggested-hours"]
              ? String(sub.attributes?.["suggested-hours"])
              : "",
            position: sub.attributes?.position || 0,
            languages: sub.attributes?.languages || {},
            "service-description":
              sub.attributes?.["service-description"] || "",
          });
        }
      });

      let products: Product[] = [];

      data.data.relationships?.products?.data?.forEach((product) => {
        const p = data?.included?.find(
          (item) => item.id == product.id && item.type === "products"
        ) as V1ProductResource | undefined;
        if (p?.id) {
          products.push({
            id: String(p.id),
            name: p.attributes?.name || "",
            mfrPart: p.attributes?.["manufacturer-part-number"] || "",
            sku: p.attributes?.sku || "",
            price: p?.attributes?.["unit-price"]
              ? String(p.attributes["unit-price"])
              : "",
          });
        }
      });

      serviceData = {
        id: String(data.data.id),
        name: data.data.attributes.name,
        phase: phaseId ? String(phaseId) : "",
        category: categoryId ? String(categoryId) : "",
        sku: sku ? String(sku) : "",
        resource: resource ? String(resource) : "",
        hours: hours ? String(hours) : "",
        teams:
          data.data?.attributes?.teams?.map((t) => {
            return { value: t?.id || "", label: t?.name || "" };
          }) || [],
        tags: tags || [],
        frequency: data.data?.attributes?.["payment-frequency"] || "",
        subservices,
        products,
        languages: data.data?.attributes?.languages || {},
        "service-description":
          data.data?.attributes?.["service-description"] || "",
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    serviceData,
    isLoading,
    error: error ?? customError,
    refetch,
    isFetching,
  };
};
export default useGetServiceById;
