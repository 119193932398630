import React, { useEffect, useState, useRef } from "react";
import { ServicesTable } from "../../../Services/ServicesTable/ServicesTable";
import { Form, Row, Col } from "react-bootstrap";
import { ColumnDef } from "@tanstack/react-table";
import { Service, Subservice } from "../common/types";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Option } from "../Show/types";
import useListLanguageFields from "../common/api/useListLanguageFields";

function ServiceLanguage({ servicePassed, setServicePassed }) {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [selectedLanguageField, setSelectedLanguageField] = useState({
    label: "Service Description",
    value: "service-description",
  });
  const [service, setService] = useState<Service[]>([{ ...servicePassed }]);
  const [focusedId, setFocusedId] = useState<string | null>(null);
  const [fieldOptions, setFieldOptions] = useState<Option[]>([]);
  const { fields } = useListLanguageFields();

  const getLanguageFieldValue = (item) => {
    return selectedLanguageField.value === "service-description"
      ? item[selectedLanguageField.value]
      : item.languages[selectedLanguageField.value];
  };

  useEffect(() => {
    if (servicePassed.id && !service?.[0].id) {
      setService([servicePassed]);
    }
  }, [servicePassed]);

  useEffect(() => {
    if (fields?.length && !fieldOptions.length) {
      setFieldOptions([
        { label: "Service Description", value: "service-description" },
        ...fields,
      ]);
    }
  }, [fields]);

  const getColumns = (serviceType) => {
    const columns: ColumnDef<Service | Subservice>[] = [
      {
        header: `${serviceType} Name`,
        cell(props) {
          return (
            <Form.Control
              type="text"
              defaultValue={props.row.original.name}
              disabled
              readOnly
              className={`${serviceType.toLowerCase()}LanguageServiceNames`}
            />
          );
        },
      },
      {
        header: selectedLanguageField.label,
        cell(props) {
          return (
            <Form.Control
              key={selectedLanguageField.value}
              as="textarea"
              rows={1}
              value={getLanguageFieldValue(props.row.original)}
              autoFocus={focusedId === props.row.original.id.toString()}
              onFocus={(e) => {
                e.stopPropagation();
                setFocusedId(props.row.original.id.toString());
                var val = e.target.value;
                e.target.value = "";
                e.target.value = val;
              }}
              onResize={(e) => e.stopPropagation()}
              onChange={(e) => {
                let item = { ...props.row.original };
                if (selectedLanguageField.value === "service-description") {
                  item["service-description"] = e.target.value;
                } else {
                  item.languages = {
                    ...item.languages,
                    [selectedLanguageField.value]: e.target.value,
                  };
                }
                props.row.original = { ...item, isChanged: true };
                setServiceData(props.row.original);
              }}
            />
          );
        },
      },
    ];
    return columns;
  };

  const onUpdateServices = (subservices) => {
    const updatedService = service.map((s) => {
      s.subservices = subservices;
      return s;
    });
    setService(updatedService);
    setServicePassed(updatedService[0]);
  };

  const setServiceData = (item) => {
    const services = service ? service : [];
    let newlyUpdatedService = [...services].map((obj) => {
      if (obj?.subservices && obj.subservices.length > 0) {
        obj.subservices.forEach((sub) => {
          if (sub.id == item.id) {
            const newSubservices =
              obj?.subservices?.filter(
                (subservice) => subservice.id !== item.id
              ) || [];
            obj.subservices = [...newSubservices, item];
          }
        });
      }
      if (obj.id === item.id) {
        return item;
      } else {
        return obj;
      }
    });
    setService(newlyUpdatedService);
    setServicePassed(newlyUpdatedService[0]);
  };

  return (
    <>
      <Row
        style={{
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <Col style={{ marginLeft: "16px" }} sm={7}>
          <Row style={{ alignItems: "center" }}>
            <Col className="instructionalText" sm={4}>
              Show / edit content for:
            </Col>
            <Col>
              <div style={{ width: "300px", marginLeft: "-40px" }}>
                <Select
                  onChange={(e) => setSelectedLanguageField(e!)}
                  className="basic-single"
                  value={selectedLanguageField}
                  options={fieldOptions}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <ServicesTable
        servicesPassed={service}
        onUpdateServices={onUpdateServices}
        serviceColumns={getColumns("Service")}
        subserviceColumns={getColumns("Subservice")}
        addSubservice={() => null}
        deleteService={() => null}
        hasSettings={false}
        hasDragAndDrop={true}
        isCollapsible={true}
        startUncollapsed={true}
      />
    </>
  );
}

export default ServiceLanguage;
